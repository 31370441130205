@use '@angular/material' as mat;
@use '/src/styles/_theming.scss' as theming;

@include mat.core();
@include mat.all-component-typographies();

$theme: mat.define-theme(
  (
    color: (
      theme-type: light,
      primary: mat.$blue-palette
    ),
    typography: (
      brand-family: 'Comic Sans',
      bold-weight: 500
    ),
    density: (
      scale: -1
    )
  )
);

body {
  &.light-theme {
    @include mat.all-component-themes($theme);
  }

  .mat-error {
    @include theming.error();
  }

  .mat-input-element {
    @include theming.no-wrap();
  }

  b {
    font-weight: bold;
  }

  .mat-snack-bar-container.notification-base {
    padding: 0;
    border-radius: 25px;
    background-color: transparent;
  }
}
